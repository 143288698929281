<template lang="pug">
  div.kt-wizard-v1__wrapper
    div.kt-form
      div.kt-wizard-v1__content(data-ktwizard-type="step-content" data-ktwizard-state="current")
        div.kt-form__section.kt-form__section--first
            span.header-summary-title {{'pages.setup_wizard.summary' | translate}}
            div.mt-5.row
              div.kt-wizard__company-pic
                img(:src="company.companyLogoUrl" alt="")
              div.organization-info
                div.summary-company-name
                  span {{'pages.setup_wizard.my_organization' | translate}}:
                  span {{ company.companyName }}
                div.row
                  div.organization-info-details-titles
                   span {{'pages.setup_wizard.number_of_users' | translate}}:
                   span  {{'pages.setup_wizard.number_of_active_users' | translate}}:
                   span  {{'pages.setup_wizard.number_of_departments' | translate}}:
                   span  {{'pages.setup_wizard.number_of_locations' | translate}}:
                   span  {{'pages.setup_wizard.number_of_positions' | translate}}:
                   span  {{'pages.setup_wizard.number_of_roles' | translate}}:
                  div.organization-info-details-values
                   span {{ (setupTableEmployees.table || []).length }}
                   span {{ countOfAciveUsers }}
                   span {{ optionsLength('department') }}
                   span {{ optionsLength('location') }}
                   span {{ optionsLength('position') }}
                   span {{ optionsLength('role') }}
      .kt-form__actions
        v-btn(@click="prevStep" text) {{ 'ui.buttons.go_back'  | translate}}
        v-btn(@click="nextStep") {{ 'ui.buttons.finish'  | translate}}
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  name: 'SetupSummary',

  components: {
  },

  data: () => ({
    company: {}
  }),

  async  mounted () {
    let data = await api.getSettingCompanyInfo()
    this.company = data
  },

  methods: {
    async nextStep () {
      const { errors } = await api.onComleteSetup()

      if (!errors) {
        this.$router.push('/')
      }
    },

    optionsLength (key) {
      return (this.setupTableEmployees.options && this.setupTableEmployees.options[key]) ? this.setupTableEmployees.options[key].length : 0
    },

    prevStep () {
      this.$router.push('/setup/employees')
    }
  },

  computed: {
    ...mapGetters('setupWizard', ['setupTableEmployees', 'isSyncProviderStep']),

    countOfAciveUsers () {
      return (this.setupTableEmployees.table || []).filter(item => item.active).length
    }
  }
}
</script>

<style lang="scss" scoped>
.header-summary-title{
  font-weight: 600;
  font-size: 25px;
}
.organization-info {
  margin-left:75px ;
  display: flex;
  flex-direction: column;

    .summary-company-name{
      height: 148px;
      display: flex;
      flex-direction: column;
    }

    .organization-info-details-titles{
      display: flex;
      flex-direction: column;
    }

     .organization-info-details-values{
      display: flex;
      flex-direction: column;
      padding-left:50px ;
    }
  }

  .kt-wizard__company-pic {
    position: relative;
    width: 148px;
    height: 148px;

    img {
      width: 100%;
      height: 100%;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    }
  }
</style>
